import {RecaptchaFormHandler} from "./_recaptcha-form-handler";
import {FormManager} from "./_form-interactions";

declare global {
    interface Window {
        formManager: FormManager;
    }
}

window.formManager = new FormManager();
new RecaptchaFormHandler();
