declare global {
    interface Window {
        grecaptcha: {
            ready: (callback: () => void) => void;
            execute: (siteKey: string, options: { action: string }) => Promise<string>;
        };
    }
}

/**
 * Class responsible for handling Google Recaptcha v3 loading and form submission.
 */
export class RecaptchaFormHandler {
    private recaptchaLoaded: boolean;
    private readonly recaptchaPublicKey: string | null;

    /**
     * Initializes the RecaptchaFormHandler.
     */
    constructor() {
        this.recaptchaLoaded = false;
        this.recaptchaPublicKey = this.getRecaptchaPublicKey();

        if (!this.recaptchaPublicKey) throw new Error("Recaptcha public key not found in meta tags.");

        this.observeForms();
    }

    /**
     * Retrieves the Recaptcha public key from a meta tag.
     * @returns {string} The Recaptcha public key.
     */
    private getRecaptchaPublicKey(): string | null {
        const metaTag = document.querySelector('meta[name="recaptcha-public"]');

        return metaTag ? metaTag.getAttribute("content") : null;
    }

    /**
     * Sets up an Intersection Observer to monitor when forms are visible on the page.
     * It loads Recaptcha when a form becomes visible and then stops observing.
     */
    observeForms() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !this.recaptchaLoaded) {
                        this.loadRecaptcha();
                        observer.disconnect();
                    }
                });
            },
            {threshold: 0.1},
        );

        document.querySelectorAll("[data-recaptcha-form]").forEach((form) => observer.observe(form));
    }

    /**
     * Dynamically loads the Google Recaptcha v3 script and initializes it.
     * Sets a flag when loaded to prevent re-loading.
     */
    loadRecaptcha() {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaPublicKey}`;
        script.onload = () => {
            this.recaptchaLoaded = true;

            window.grecaptcha.ready(() => {
                document.querySelectorAll("[data-recaptcha-form]").forEach((form) => {
                    const formId = form.id;
                    const action = form.getAttribute("data-recaptcha-action");
                    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
                    const tokenFieldId = `recaptcha_token_${formId}_${randomNumber}`;

                    form.insertAdjacentHTML(
                        "beforeend",
                        `<input type="hidden" name="_recaptcha_token_${formId}" id="${tokenFieldId}">`,
                    );

                    if (!action) return;

                    window.grecaptcha.execute(this.recaptchaPublicKey!, {action: action}).then((token: string) => {
                        const tokenField = document.getElementById(tokenFieldId) as HTMLInputElement;

                        if (!tokenField) return;

                        tokenField.value = token;
                    });
                });
            });
        };

        document.head.appendChild(script);
    }
}
