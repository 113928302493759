/**
 * Class responsible for managing form operations such as clearing input fields
 * and toggling the state of the send button in a form.
 */
export class FormManager {
    /**
     * Clears all input values from the specified form.
     *
     * @param {string} formId - The ID of the form to clear values from.
     */
    clearForm(formId: string): void {
        const form = document.getElementById(formId) as HTMLFormElement;

        // Clear text, password, number, etc.
        const inputFields = form.querySelectorAll<HTMLInputElement>(
            'input:not([type="hidden"]):not([type="submit"]):not([type="file"])',
        );
        inputFields.forEach((input) => (input.value = ""));

        // Handle file inputs
        const fileInputs = form.querySelectorAll<HTMLInputElement>('input[type="file"]');

        fileInputs.forEach((input) => {
            const fileUpload = input.closest(".file-upload");

            if (fileUpload) {
                fileUpload.setAttribute("data-state", "empty");
                const fileUploadFiles = fileUpload.querySelector(".file-upload__files");
                if (fileUploadFiles) fileUploadFiles.innerHTML = "";
            }

            input.value = ""; // Reset the file input value
        });
    }
}
